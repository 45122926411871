<template>
  <div class="mx-24 2xl:mx-96">
    <h1 class="text-red-600 text-5xl">Not Found!</h1>
    <img src="../assets/404.svg" alt="Octopus attacking scuba diver" />
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
